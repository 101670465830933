body {
    .modal {
        .modal-header {
            background-color: $green;

            .modal-title {
                color: #fff;
                font-weight: 500;
            }
        }


        .btn-primary {
            background-color: $green;
            border-bottom: solid 2px $greenDark;

            &:hover {
                background-color: $greenDark;
            }
        }

        .btn-warning {
            background-color: $red;
            border-bottom: solid 2px $redDark;
            margin:0 auto 20px;

            &:hover {
                background-color: $redDark;
            }
        }
        .btn-cancel {
            display:block;
            margin: 10px auto 0;
        }
    }
}
