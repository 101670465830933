body {
  #homeScreen {
	.table {

	  td {
		height: 80px;

		.btn-default {
		  &.check, &.Suppliers, &.Administrators {
			background-color: $red !important;
			color: #fff !important;
		  }
		  margin-top: 13px;
		}

		.btn-link {
		  margin-top: 13px;
		  margin-left: -13px;
		}
		.btn-group {
		  margin-right:10px;
		}
	  }
	}
  }
}