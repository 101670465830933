.search-container {
    background-color: #FFF;
    position: relative;
    border-radius: 8px;
    padding: 0px 50px 0px 10px;
    border: solid 1px #BFBFBF;
    height:45px;
    overflow:hidden;
    width:230px;
    display:inline-block;
    vertical-align: middle;
}
.search-box {
    background-color: transparent;
    outline: none;
    height: 45px;
    font-size: 14px;
    border: 0;
    width: 100%;

    line-height:45px;
}
.search-button {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #F2F2F2;
    border: 0;
    width: 45px;
    height: 45px;
    outline: 0;
    color:#BFBFBF;
    cursor:pointer;
}
