body {
  .alert {
	&.alert-success {
	  background-color: $green;
	  border: solid 1px $greenDark;
	  color: #fff;
	}
	letter-spacing: 0.8px;
	font-weight: 400;

	&.alert-danger {
	  background-color: $red;
	  border: solid 1px $redDark;
	  color: #fff;
	}

	  &.alert-default {
		  background-color:#fff;
		  border: solid 1px #EAEAEA;
	  }
  }
}