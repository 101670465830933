body {
    .btn {
        border: 0;
        letter-spacing: 0.8px;
        transition: all 0.4s ease;
        border-radius: 10px;

        &:hover {
            transition: all 0.4s ease;
        }

        &.export {
            margin-top: 11px;
        }

        &.margin-right{
            margin-right: 10px;
        }
        &.btn-review {
            margin-right: 10px;
            background-color: $red !important;
            color: #fff !important;
            margin-top: 11px;

            &:hover {
                background-color: $redDark;
            }
        }

        &.btn-primary {
            background-color: $red;
            border-bottom: solid 2px $redDark;
            padding: 10px 30px;

            &:hover {
                background-color: $redDark;
            }

            .glyphicon-trash {
                top: 3px;
                left: -6px;
            }
        }

        &.btn-green {
            background-color: $green;
            border-bottom: solid 2px $greenDark;
            padding: 10px 30px;
            color: #fff;

            &:hover {
                background-color: $greenDark;
            }

            .glyphicon-trash {
                top: 3px;
                left: -6px;
            }
        }

        &.btn-back {
            margin-bottom: 20px;
        }

        &.btn-link {
            color: $red;
            font-weight: 500;
            letter-spacing: inherit;
        }

        &.btn-default {
            background-color: #eaeef0;
            color: $greyDark;

            &:hover {
                background-color: $red;
                color: #fff;
            }
        }
    }
}
