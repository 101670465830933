body {
  .has-error {
    .help-block, .control-label {
      color: $red;
    }

    .form-control {
      border-color: $red;
    }
  }

  textarea.error, select.error,input.error {
    border: solid 1px $red;

  }
} 
