body {
	.tabs {
		.pagination {
			margin-top:0px;
		}
	}
  .pagination {
	.active {
	  span {
		background-color:$green;
		border: solid 1px $greenDark;
	  }
	}
	span,a {
	  color:$green;

	}
  }
}