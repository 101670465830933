body {
  .table {
    border-bottom: solid 1px #eaeef0;
    margin-bottom: 5px;

    th {
      background-color: #eaeef0;
      font-weight: 500;
      color: #758f9a;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      font-size: 13px;
      border-bottom: none;
      padding: 18px 0 15px 8px !important;

      &.text-right {
        padding-right: 10px !important;
      }
    }

    tr.active-0 {
      td {
        opacity: 0.5;
      }
    }

    tr.clickable-row {
      cursor: pointer;
    }

    td {
      vertical-align: middle;

      a.btn-link {
        color: $red;
        &:hover{
          color:#fff;
        }
      }
      a {
        transition: color 250ms ease-in-out;
      }
      a:hover {
        color:$red;
        text-decoration: none;
        transition: color 250ms ease-in-out;
      }

      &.status {
        width: 100px;
      }

      .name {
        font-size: 16px;
      }

      &.quarter {
        padding-top: 14px;

        strong {
          font-size: 16px;
          color: #000;

        }
      }

      &.text-right {
        .glyphicon {
          position: relative;
          top: 14px;
          right: 10px;
          opacity: 0.5;
        }
      }

      .circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $green;
        margin-top: 16px;
        margin-left: 15px;

        &.Closed {
          background-color: $red;
        }

        &.check {
          background-color: #e6aa09;
        }
      }
    }
      .button-information{
          position: relative;
          top: 6px;
          margin-right: 5px;
      }
  }
}
