#usersScreen {
  .nav-tabs {
	margin-top:15px;
	border-bottom: solid 4px $red;
	padding-bottom: 1px;

	a {
	  border:0;
	}

	li.active {
	  a {
		color:#fff;
		font-weight:700;
		background-color:$red;
	  }
	}
  }

  .table {
	td {
	  vertical-align: middle;
	}
	.icon {
	  span {
		font-size:17px;
		top:3px;
		left:5px;
	  }
	}
	.name {
	  strong {
		font-weight:500;
		font-size:16px;
	  }
	}
  }
	.nav-action-buttons {
		margin-bottom:0;
	}
}