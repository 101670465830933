body {
  .navbar {
	height:75px;
	#logo {
		width:150px;
	}
	.navbar-nav {
	  margin-top:10px;
	}
  }
}