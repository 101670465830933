// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "components/alerts.component";
@import "components/buttons.component";
@import "components/error.component";
@import "components/form.component";
@import "components/modals.component";
@import "components/pagination.component";
@import "components/panels.component";
@import "components/search.component";
@import "components/table.component";
@import "components/tooltip.component";

@import "screens/home.screen";
@import "screens/login.screen";
@import "screens/users.screen";
@import "sections/header.section";

p, h1, h2, h3, h4, a, label, input, dl, dt, td {
  font-family: "Roboto";
  color: $textColor;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  height: 100%;
}

body {
  &.background {
	background-image: url(../img/bg3.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	.navbar {
	  display: none;
	}

	.container,#app {
	  height: 100%;
	}

	.panel {
	  margin-top:200px;
	  transition: all 0.5s ease;

	  &:hover {
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
		transform: translateY(0px);
		transition: all 0.5s ease;
	  }
	}

  }

  h1 {
	font-weight: 300;
  }
}

.row.no-margin-top {
    margin-top:0 !important;
}
