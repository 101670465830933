body {
  .panel {
    border: 0;
	  box-shadow: 0 3px 15px 0 rgba(0,0,0,0.05);
	  border-top-left-radius: 20px;
	  border-top-right-radius: 20px;

    &.margin-top {
      margin-top: 30px;
    }

    .panel-body {
      .row {
        margin-top: -15px;
      }
    }

    &.panel-primary {
      .panel-heading {
        background-color: $red;
        color: #fff;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 18px;
        border-bottom: solid 1px $redDark;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

      }

      border-radius: 20px;
    }

    &.panel-info {
      .panel-heading {
        background-color: #374a53;
        color: #fff;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 18px;
        border-bottom: solid 1px #374a53;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

      }

      border-radius: 20px;
    }

    &.panel-default {
		background-color:transparent;
      .panel-heading {
        background-color: #eaeef0;
        color: #758f9a;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        font-size: 15px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
		.panel-body {
			background-color:#fff;
		}
    }

    .nav-action-buttons {
      margin-bottom: 15px;
      display: block;
      overflow: hidden;

      .btn-default {
        height: 45px;
        line-height: 45px;
        padding: 0 20px;
      }
    }
  }
}